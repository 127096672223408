class EnumsModel {
    // 实验状态
    static experimentStatus = {
        0: "未开始",
        1: "进行中",
        2: "已结束",
        3: "已关闭"
    }

    // 已开通web的学校列表
    static webSchool = {
        idList: ["102055742013444096", "99450849243172864", "239675483795492864", "53540978547494912",
            "258950417977708544", "180001364951175168", "295157046876049408", "253449647194902528", "296051816120979456",
            "296051840255004672", "296051859775295488", "296063516752744448", "299267353294671872", "71910744303603712", "306508329578926080",
            "190753622680670208", "59977128740917248", "277074055142576128", "355650939304022016", "403125196212408320", "251769159480774656", "403495231728652288",
            "49538854775951360", "97702874556928000","500617144531161088","434813771949543424","433941816648142848"],
        nameList: ["雅安职业技术学院", "重庆城市管理职业学院", "北京青年政治学院", "广州番禺职业技术学院", "湖北铁道运输职业学院",
            "成都汽车职业技术学校", "成都航空职业技术学院", "成都工业职业技术学院", "华北水利水电大学", "广东科贸职业学院", "扬州工业职业技术学院",
            "陆军军事交通学院镇江校区", "长沙职业技术学院", "广安职业技术学院", "石嘴山工贸职业技术学院", "四川信息职业技术学院", "西南财经大学", "演示学校", "重庆工贸职业技术学院",
            "新疆农业职业技术学院", "福建信息职业技术学院", "四川邮电职业技术学院", "云南国土资源职业学院", "重庆青年职业技术学院", "重庆公共运输职业学院",
        "太原学院","天津铁道职业技术学院","郑州铁路职业技术学院"],
        routeList: ["/yazyjsxy", "/cqcsglzyxy", "/bjqnzzxy", "/gzpyzyjsxy", "/hbtdyszyxy", "/cdqczx", "/cdhkzyjsxy", "/cdgyzyjsxy",
            "/hbslsddx", "/gdkmzyxy_241128", "/yzgyzyjsxy", "/ljjsjtxyzjxq", "/cszyjsxy", "/gazyjsxy", "/szsgmzyjsxy", "/scxxzyjsxy", "/xncjdx", "/ysxy", "/cqgmzyjsxy",
            "/xjnyzyjsxy", "/fjxxzyjsxy", "/scydzyjsxy", "/fjxxzy_sqjs", "/yngtzyzyxy", "/cqqnzyjsxy", "/cqggyszyxy","/tyxy","/tjtdzyjsxy","/zztlzyjsxy"],
        idToRoute: {
            "102055742013444096": "/yazyjsxy",
            "99450849243172864": "/cqcsglzyxy",
            "239675483795492864": "/bjqnzzxy",
            "53540978547494912": "/gzpyzyjsxy",
            "258950417977708544": "/hbtdyszyxy",
            "180001364951175168": "/cdqczx",
            "295157046876049408": "/cdhkzyjsxy",
            "253449647194902528": "cdgyzyjsxy",
            "296051816120979456": "/hbslsddx",
            "296051840255004672": "/gdkmzyxy_241128",
            "296051859775295488": "/yzgyzyjsxy",
            "296063516752744448": "/ljjsjtxyzjxq",
            "299267353294671872": "/cszyjsxy",
            "71910744303603712": "/gazyjsxy",
            "306508329578926080": "/szsgmzyjsxy",
            "190753622680670208": "/scxxzyjsxy",
            "59977128740917248": "/xncjdx",
            "277074055142576128": "/ysxy",
            "355650939304022016": "/cqgmzyjsxy",
            "403125196212408320": "/xjnyzyjsxy",
            "251769159480774656": "/fjxxzyjsxy",
            "401503547130122240": "/scydzyjsxy",
            "403495231728652288": "/yngtzyzyxy",
            "49538854775951360": "/cqqnzyjsxy",
            "97702874556928000": "/cqggyszyxy",
            "500617144531161088":"/tyxy",
            "434813771949543424":"/tjtdzyjsxy",
            "433941816648142848":"/zztlzyjsxy",
        }
    }

    // 题库类型
    static questionBankType = {
        SYSTEM: "系统题库",
        PRIVATE: "私有题库",
        COMMON: "公有题库",
    }

    // 判断题答案
    static questionJudgeAnswer = {
        "true": "正确",
        "false": "错误"
    }

    // 问题选项排序
    static  questionOptionLabel = ["A", "B", "C", "D", "E", "F"]
    // 问题类型
    static questionType = {
        "Radio": "单项选择",
        "Multiple": "多项选择",
        "Judge": "判断题",
    }

    // 考核列席
    static examType={
        "single":"单题",
        "multiple":"组卷",
    }

    // 考试结果状态
    static examResultStatus = {
        UnSubmited: "未交卷",
        Submited: "已交卷",
        MarkedObjectiveScore: "客观题已阅卷",
        MarkedSubjectiveScore: "主观题已阅卷",
        CalculatedScore:"已评分"
    }

    // 考试状态
    static examStatus = {
        UnStarted: "未开始",
        Going: "进行中",
        Over: "已结束",
    }
}

export {EnumsModel}
