<template>
  <div class="page-container webSetting-page">
    <el-tabs v-model="tabShow">
      <el-tab-pane label="基础设置" name="base" class="base-pane pane-container">
        <el-form>
          <el-form-item label="网站标题">
            <el-input v-model="webConfig.base.title"></el-input>
          </el-form-item>
          <el-form-item label="主办单位">
            <el-input v-model="webConfig.base.organ"></el-input>
          </el-form-item>
          <!--    只有重庆青年显示视频地址      -->
          <el-form-item label="视频地址" v-if="userInfo.schoolId==='49538854775951360'">
            <el-input v-model="webConfig.base.videoSrc"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="轮播" name="focus" class="focus-pane pane-container">
        <div class="tools-box clearFix">
          <div class="fr">
            <el-button type="primary" plain size="small" @click="FocusMethods().clickAddOneBtn()">新增轮播图</el-button>
          </div>
        </div>
        <el-table :data="webConfig.focus.list" border fit>
          <el-table-column label="序号" align="center" width="100">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="序号" align="center" width="400">
            <template slot-scope="scope">
              <erp-uploader-one-pic style="margin-right: 30px" :img-in="scope.row.image"
                                    :uploader-id="'image'"
                                    uploader-title="图片" :uploader-size="[200,200]" :pixel-limit="[1920,520]"
                                    :size-limit="5000"
                                    @uploadSuccess="data=>fileUpload(data,webConfig.focus.list[scope.$index],'focus')"
                                    @afterDelete="data=>fileDelete(data,webConfig.focus.list[scope.$index],'focus')"></erp-uploader-one-pic>
            </template>
          </el-table-column>
          <el-table-column label="是否显示" align="center" width="150">
            <template slot-scope="scope">
              <el-select v-model="scope.row.show">
                <el-option label="显示" :value="true"></el-option>
                <el-option label="隐藏" :value="false"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" plain @click="FocusMethods().clickDeleteBtn(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="中心简介" name="center" class="center-pane pane-container">
        <el-form>
          <el-form-item label="标题">
            <el-input v-model="webConfig.center.title"></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <el-input type="textarea" v-model="webConfig.center.content" :rows="20"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="项目列表" name="experiment" class="experiment-pane pane-container">
        <el-transfer
            :titles="['可选项目列表','展示项目列表']"
            target-order="push"
            v-model="webConfig.experiment.showList"
            :props="{
                key: 'id',
                label: 'name'
              }"
            :data="webConfig.experiment.canList">
        </el-transfer>
      </el-tab-pane>
      <el-tab-pane label="友情链接" name="friend" class="friend-pane pane-container">
        <div class="tools-box clearFix">
          <div class="fr">
            <el-button type="primary" plain size="small" @click="FriendMethods().clickAddOneBtn()">新增友情链接</el-button>
          </div>
        </div>
        <el-table :data="webConfig.friend.list" border fit>
          <el-table-column label="序号" align="center" width="100">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column label="序号" align="center" width="400">
            <template slot-scope="scope">
              <erp-uploader-one-pic style="margin-right: 30px" :img-in="scope.row.image"
                                    :uploader-id="'image'"
                                    uploader-title="图片" :uploader-size="[200,200]" :pixel-limit="[280,140]"
                                    :size-limit="2000"
                                    @uploadSuccess="data=>fileUpload(data,webConfig.friend.list[scope.$index],'focus')"
                                    @afterDelete="data=>fileDelete(data,webConfig.friend.list[scope.$index],'focus')"></erp-uploader-one-pic>
            </template>
          </el-table-column>
          <el-table-column label="名称" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="跳转链接" align="center">
            <template slot-scope="scope">
              <el-input v-model="scope.row.url"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="是否显示" align="center" width="150">
            <template slot-scope="scope">
              <el-select v-model="scope.row.show">
                <el-option label="显示" :value="true"></el-option>
                <el-option label="隐藏" :value="false"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button size="mini" type="danger" plain @click="FriendMethods().clickDeleteBtn(scope.$index)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <!--按钮组-->
    <div class="bottom-button-container flex flex-center">
      <el-button type="primary" @click="clickSaveBtn()" size="small">保存官网配置</el-button>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {dateFormat, numberFormat} from "@/filter/filter";
import elDragDialog from "@/directive/el-drag-dialog";
import erpUploaderOnePic from "@/views/components/erpUploaderOnePic";
import {msg_confirm, msg_success} from "@/utils/ele_component";
import {VEAModel} from "@/model/VEAModel";
import {ExpConfigModel} from "@/model/exp/ExpConfigModel";
import {ConfigModel} from "@/model/erp/ConfigModel";

export default {
  name: "webSetting",
  // 过滤器
  filters: {
    dateFormat: dateFormat
  },
  directives: {
    elDragDialog
  },
  components: {erpUploaderOnePic},
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    return {
      tabShow: "base",
      webConfig: {
        base:{

        },
        focus: {
          list: [

          ]
        },
        center:{

        },
        friend:{
          list:[

          ]
        },
        experiment:{
          showList:[],
          canList:[

          ]
        }
      }
    }
  },
  async mounted() {
    // 获取官网配置
    this.webConfig=JSON.parse(await ConfigModel.getConfig("config_exp_officialWeb",this.userInfo.schoolId))
    // 底部按钮组大小监听
    VEAModel.bottomButtonContainerWidthChange()
  },
  methods: {
    // 通用-文件上传成功
    fileUpload(params, target, formRef) {
      let imgSrc = params[1] // 成功后文件地址
      let uploaderId = params[0] // 上传id
      this.$set(target, uploaderId, imgSrc)
      if (formRef) {
        this.$refs[formRef].validate()
      }
    },
    // 通用-文件删除
    fileDelete(params, target, formRef) {
      let uploaderId = params[0] // 上传id
      this.$set(target, uploaderId, "")
      if (formRef) {
        this.$refs[formRef].validate()
      }
    },
    // 点击保存配置按钮
    async clickSaveBtn(){
      if (await msg_confirm("确认要保存官网配置吗？","保存官网配置")) {
        if(await ExpConfigModel.editOfficialWebConfig(this.webConfig)){
          msg_success("保存官网配置成功！")
        }
      }
    },
    // 轮播相关
    FocusMethods() {
      let $this = this;
      return {
        // 新增一个
        clickAddOneBtn() {
          $this.webConfig.focus.list.push({
            show: true
          })
        },
        // 点击删除按钮
        async clickDeleteBtn(index) {
          if (await msg_confirm("确认要删除此轮播图吗？","删除轮播图")) {
            $this.webConfig.focus.list.splice(index,1)
          }
        }
      }
    },
    // 友情链接相关
    FriendMethods() {
      let $this = this;
      return {
        // 新增一个
        clickAddOneBtn() {
          $this.webConfig.friend.list.push({
            show: true
          })
        },
        // 点击删除按钮
        async clickDeleteBtn(index) {
          if (await msg_confirm("确认要删除此友情链接吗？","删除友情链接")) {
            $this.webConfig.friend.list.splice(index,1)
          }
        }
      }
    }
  }
}
</script>

<style>
</style>
<style scoped lang="less">
.pane-container {
  .tools-box {
    margin-bottom: 10px;
  }
}
</style>
